import styled from "styled-components";

export const FormRow = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between !important;
    & div:first-of-type {
        margin-right: 5px;
    }
    @media (max-width: ${props => props.theme.breakPoints.xs}) {
        flex-direction: column;
        & div:first-of-type {
            margin-right: 0px;
        }
    }

`

export const FormCol = styled.div`
    margin-bottom: 15px;

`