import React from "react";
import styled from "styled-components";
import NakedButton from "@common/buttons/NakedButton";
import { UnmountClosed } from 'react-collapse';
import {domains, secondLevelDomains, topLevelDomains} from "@helpers";

var mailcheck = require("mailcheck");

const ReccomendationWrapper = styled.div`
    width: 100%;
    & div {
        width: 100%;
    }

`

const ReccomendationText = styled.p`
    color: gray;
    margin: 0px;
    text-align: right;
    margin-top: -3px;
    padding: 0px 7px;
    letter-spacing: .8px;
    margin-bottom: 12px;
    & button {
        display: inline !important;
        color: ${props => props.theme.colors.primary};
        width: auto !important;
    }
`

// Takes in a string which is supposed to be an email and spits out a reccomedation on what the email is supposed to be
// Returns a float right string with a call back to set the email into parent component
class EmailCheck extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            suggestedEmail: null,
            canceledEmailSuggestion: false
        }
    }

    // Only update the component if the email props has change
    shouldComponentUpdate(nextProps, nextState) {
        if (nextProps.email !== this.props.email) {
            return true
        }
        if (nextState.canceledEmailSuggestion !== this.state.canceledEmailSuggestion) {
            return true
        }
        return false
    }

    // Take in the new email and check it with the check email helper function
    UNSAFE_componentWillReceiveProps(nextProps) {
        if (nextProps.email) {
            if (nextProps.email.length > 5) {
                this.checkEmail(nextProps.email)
            }
        } else {
            // if there is not next props email then set the suggested email to null
            this.setState({
                suggestedEmail: null
            })
        }
    }

    // Takes in the user inputted email and spits out a possibly properly formatted email to set to state
    checkEmail = (email) => {
        var that = this;
        mailcheck.run({
            email: email,
            domains: domains,
            topLevelDomains: topLevelDomains,
            secondLevelDomains: secondLevelDomains,
            suggested: function(suggestion) {
                if (email !== suggestion["full"]) {
                    that.setState({
                        suggestedEmail: suggestion["full"]
                    });
                } else {
                    that.setState({
                        suggestedEmail: null
                    });
                }
            },
            empty: function() {
                that.setState({
                    suggestedEmail: null
                });
            }
        });
    }

    // Cancels all email suggesstions until a full page reload
    cancelEmailSuggestion = () => {
        this.setState({
            canceledEmailSuggestion: true
        })
    }

    changeToSuggestedEmail = () => {
        this.props.changeToSuggestedEmail(this.props.fieldToChange, this.state.suggestedEmail)
    }

    render() {
        const { suggestedEmail, canceledEmailSuggestion } = this.state;

        return (
            <ReccomendationWrapper>
                <UnmountClosed isOpened={suggestedEmail && !canceledEmailSuggestion}>
                    <ReccomendationText>
                        Did you mean {suggestedEmail}? <NakedButton type="button" onClick={this.changeToSuggestedEmail}>Yes</NakedButton> / <NakedButton type="button" onClick={this.cancelEmailSuggestion}>No</NakedButton>
                    </ReccomendationText>
                </UnmountClosed>
            </ReccomendationWrapper>
        );
    }
}

export default EmailCheck;
